import axios from 'axios';
// var BASE_URL = "http://localhost:5000/api/";
var BASE_URL = 'https://api.nirantara.solutions/api';


const fetchClient = () => {
  const defaultOptions = {
    baseURL: BASE_URL,
    // timeout: 20000,
    headers: {
      "Content-Type": "application/json",
    },
  };
 
  let instance = axios.create(defaultOptions);
  // instance.defaults.timeout = 30000

  instance.interceptors.request.use(  
    function (config) {
      const token = localStorage.getItem("token");
      if (!!token && token != "null") {
        config.headers.Authorization = token ? `Bearer ${token}` : `Bearer ''`;
      }
      // Timeout
      config.timeout = 30000; // Milliseconds
     return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  return instance;
};
export default fetchClient();